import React from "react"

export function SvlLogo(props: any) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
      y="0"
      x="0"
      id="Слой_2"
      version="1.1"
      viewBox="0 0 409.39 48"
      {...props}
    >
      <g transform="translate(-749.64 -124)" id="g16">
        <path
          id="path4"
          d="M752.35 137.41c.03.01.45.07 1.27.19l1.87.01c.25 0 .42.39.51 1.18 0 1.15.06 6.8.18 16.98.05.41.1.66.16.76-.06.31-.09.56-.09.76v.18c0 .2.03.45.09.76 0 .57-.31.85-.94.84l-4.82-.04c-.31 0-.51-.11-.6-.34v-1.87c0-1.05-.08-1.78-.25-2.21l.07-.42v-9.84c0-.51-.05-.99-.16-1.45 0-.12.03-.23.09-.33-.06-.74-.09-1.33-.09-1.79v-2.37c0-.62.4-.93 1.19-.93l.58.09c.23-.11.54-.16.94-.16zm19.26.33l5 .04.34.25c.05 1.96.07 3.38.07 4.26v2.79c.12.83.18 1.28.18 1.36v9.93c0 .31.08.73.25 1.27-.14.97-.37 1.45-.69 1.45l-1.1-.01c-.73-.01-1.29.01-1.69.07-.4-.06-.97-.09-1.7-.1h-.76c-.17-.06-.25-.23-.25-.52v-3.48c0-.22-.05-.59-.16-1.1 0-.11.05-.3.16-.58-.06-.1-.11-.35-.16-.76.11-.41.16-.72.16-.94 0-.23-.2-.48-.6-.76l-9.93-.09c-.79 0-1.18-.54-1.18-1.61v-2.46c0-.45.17-.79.51-1.02 2.26-.04 3.96-.05 5.09-.04l5.42.04c.29-.13.43-.41.43-.85v-6.53c.02-.41.22-.61.61-.61z"
          className="st0"
          fill="#665f5f"
        />
        <path
          id="path6"
          d="M786.26 142.96h-.01v16.07h-6.38v-21.38h6.39zm3.6-5.31h18.07v21.38h-6.38v-16.07h-11.69z"
          className="st0"
          fill="#665f5f"
        />
        <path
          id="path8"
          d="M819.43 137.57h8.36c5.57 0 8.36.24 8.36.73.93.22 1.97 1.23 3.14 3.03.49 1.45.73 2.5.73 3.14-.08 3.55-.12 6.02-.12 7.39 0 1.73-.57 3.43-1.7 5.09-1.61 1.53-3.23 2.3-4.84 2.3-4.22-.08-7.57-.12-10.05-.12h-5.7c-3.01 0-5.1-1.45-6.29-4.36-.25-1-.37-1.68-.37-2.06v-.85c0-.24.04-.36.12-.36l-.12-.61v-6.17c0-3.04 1.13-5.14 3.39-6.3 1.09-.56 2.79-.85 5.09-.85zm-2.3 8.6v4.24c0 1.64.89 2.73 2.66 3.27.83.16 2.12.24 3.88.24h5.57c2.13 0 3.5-.52 4.12-1.57.49-.8.73-2.09.73-3.88l-.12-.85.12-1.09c0-1.87-.97-3.12-2.91-3.75-.56-.08-1-.12-1.33-.12h-8.36c-2.13 0-3.5.73-4.12 2.18-.16.32-.24.77-.24 1.33z"
          className="st0"
          fill="#665f5f"
        />
        <path
          id="path10"
          d="M858.53 137.53h.33c.67 0 1.49.02 2.46.07.06 0 .14-.02.24-.07.72.05 1.29.07 1.73.07h2.37c.36 0 .72.03 1.08.09.06 0 .14-.03.24-.09 1.02.06 1.87.09 2.55.09h2.54c.77 0 1.15.85 1.15 2.54v1.08c0 .06.02.14.07.24-.06.88-.5 1.31-1.31 1.31-.53 0-.8.03-.82.09l-.48-.09h-.16c-.6 0-1.09.03-1.48.09-.37-.06-.73-.09-1.08-.09l-1.15.09a8.36 8.36 0 00-1.64-.16h-.4c-.39 0-.61.02-.66.07-.38-.05-.68-.07-.91-.07h-3.03c-1.68 0-2.78.44-3.29 1.31-.6 0-.93 1.67-.99 5 0 1.94.44 3.2 1.31 3.78.89.55 1.65.82 2.3.82.31 0 .61-.03.9-.09l3.87.16.33-.07c.29.05.56.07.82.07h.24c.54 0 .81-.02.82-.07.47.05.85.07 1.15.07h4.02c.94 0 1.4.25 1.4.75-.06.29-.09.45-.09.49 0 .3.03.68.09 1.15-.06.8-.09 1.51-.09 2.14 0 .49-.49.73-1.48.73-.78 0-1.35-.02-1.72-.07-.25 0-.6.02-1.06.07-2.78-.05-4.94-.07-6.5-.07-.97 0-2.17.02-3.6.07-3.88 0-6.46-1.04-7.72-3.12-.41-.25-.79-1.18-1.15-2.79v-1.48c0-.49-.05-.95-.16-1.39.11-.47.16-.77.16-.91 0-.49-.03-1.44-.09-2.87 0-3.29.47-5.35 1.4-6.15.28-.68 1.07-1.33 2.37-1.97.61-.55 2.31-.82 5.12-.82z"
          className="st1"
          fill="#399bd8"
        />
        <path
          id="path12"
          d="M881.75 142.7v-.16h-6.48v-4.51c.04-.26.17-.39.39-.39h23.53c1.82 0 2.73.91 2.73 2.72v4.67c0 1.01-.59 1.91-1.76 2.72 2.04 0 3.21 1.62 3.51 4.87v1.55c0 2.49-.97 4.05-2.91 4.67-.22.13-.55.19-.99.19h-24.12c-.26 0-.39-.13-.39-.39v-12.86h6.39v.02h12.48c1.04 0 1.55-.52 1.55-1.55 0-1.04-.52-1.55-1.55-1.55zm-.09 11.46h14.04c1.16-.17 1.75-.75 1.75-1.75 0-.84-.58-1.35-1.75-1.55h-14.04z"
          className="st1"
          fill="#399bd8"
        />
        <path
          id="path14"
          d="M933.9 137.65v21.38h-6.36v-16.08h-12.17c0 6.35-.53 10.69-1.6 13.02-.95 2.04-2.59 3.06-4.93 3.06h-3v-5.32c1.54 0 2.45-.71 2.73-2.14.28-1.43.42-6.07.42-13.93h24.91z"
          className="st1"
          fill="#399bd8"
        />
      </g>
      <g transform="translate(-749.64 -124)" id="g84">
        <path
          id="path18"
          d="M973.3 142.07c0-2.62 1.93-4.41 4.39-4.41 1.51 0 2.56.73 3.23 1.69l-.89.5a2.875 2.875 0 00-2.34-1.24c-1.87 0-3.29 1.43-3.29 3.47 0 2.02 1.42 3.46 3.29 3.46.97 0 1.85-.51 2.34-1.24l.91.5c-.72.97-1.74 1.69-3.25 1.69-2.46-.01-4.39-1.8-4.39-4.42z"
          className="st0"
          fill="#665f5f"
        />
        <path
          id="path20"
          d="M982.29 146.32v-8.53h1.06v6.66l4.91-6.66h1.09v8.53h-1.06v-6.8l-4.97 6.8z"
          className="st0"
          fill="#665f5f"
        />
        <path
          id="path22"
          d="M990.99 142.07c0-2.62 1.93-4.41 4.38-4.41 1.51 0 2.56.73 3.24 1.69l-.9.5a2.875 2.875 0 00-2.34-1.24c-1.87 0-3.29 1.43-3.29 3.47 0 2.02 1.42 3.46 3.29 3.46.97 0 1.85-.51 2.34-1.24l.91.5c-.72.97-1.74 1.69-3.25 1.69-2.45-.01-4.38-1.8-4.38-4.42z"
          className="st0"
          fill="#665f5f"
        />
        <path
          id="path24"
          d="M1002.09 146.32v-7.58h-2.7v-.95h6.47v.95h-2.71v7.58z"
          className="st0"
          fill="#665f5f"
        />
        <path
          id="path26"
          d="M1007.26 146.32v-8.53h5.59v.95h-4.53v2.75h4.44v.95h-4.44v2.94h4.53v.95h-5.59z"
          className="st0"
          fill="#665f5f"
        />
        <path
          id="path28"
          d="M1021.83 146.32v-7.02l-2.9 7.02h-.43l-2.89-7.02v7.02h-1.06v-8.53h1.52l2.65 6.42 2.66-6.42h1.52v8.53z"
          className="st0"
          fill="#665f5f"
        />
        <path
          id="path30"
          d="M1025.94 137.8v3.39h2.37c1.69 0 2.67 1.18 2.67 2.57s-.96 2.57-2.67 2.57h-3.43v-8.53zm0 4.33v3.25h2.24c1.01 0 1.7-.67 1.7-1.62 0-.95-.69-1.62-1.7-1.62h-2.24zm5.96 4.19v-8.53h1.06v8.53z"
          className="st0"
          fill="#665f5f"
        />
        <path
          id="path32"
          d="M1041.55 146.32v-8.53h3.8c1.48 0 2.39.9 2.39 2.17 0 1.07-.72 1.79-1.5 1.96.91.14 1.66 1.07 1.66 2.1 0 1.37-.91 2.3-2.47 2.3zm5.09-6.2c0-.77-.51-1.38-1.47-1.38h-2.56v2.75h2.56c.96 0 1.47-.59 1.47-1.37zm.17 3.79c0-.78-.54-1.47-1.59-1.47h-2.61v2.94h2.61c1 0 1.59-.56 1.59-1.47z"
          className="st0"
          fill="#665f5f"
        />
        <path
          id="path34"
          d="M1048.96 145.07l.63-.68c.51.66 1.51 1.14 2.56 1.14 1.34 0 2.15-.61 2.15-1.6 0-1.06-.91-1.51-2.21-1.51h-1.74v-.95h1.74c1.14 0 2.07-.42 2.07-1.41 0-.95-.92-1.46-2.05-1.46-.97 0-1.78.36-2.42 1.04l-.59-.67c.64-.74 1.73-1.32 3.08-1.32 1.68 0 3.06.83 3.06 2.28 0 1.23-1.05 1.84-1.84 1.98.78.06 1.97.73 1.97 2.1 0 1.42-1.28 2.46-3.2 2.46-1.49.01-2.67-.63-3.21-1.4z"
          className="st0"
          fill="#665f5f"
        />
        <path
          id="path36"
          d="M1063.14 146.32l-.75-1.89h-4.25l-.75 1.89h-1.21l3.43-8.53h1.32l3.44 8.53zm-2.87-7.42l-1.82 4.59h3.62z"
          className="st0"
          fill="#665f5f"
        />
        <path
          id="path38"
          d="M1065.47 146.32v-8.53h1.06v6.66l4.91-6.66h1.09v8.53h-1.06v-6.8l-4.97 6.8z"
          className="st0"
          fill="#665f5f"
        />
        <path
          id="path40"
          d="M1081.81 146.32v-7.02l-2.9 7.02h-.43l-2.89-7.02v7.02h-1.06v-8.53h1.52l2.65 6.42 2.66-6.42h1.52v8.53z"
          className="st0"
          fill="#665f5f"
        />
        <path
          id="path42"
          d="M1084.51 142.07c0-2.52 1.7-4.41 4.23-4.41 2.52 0 4.23 1.89 4.23 4.41 0 2.52-1.71 4.41-4.23 4.41-2.53 0-4.23-1.89-4.23-4.41zm7.37 0c0-2-1.23-3.47-3.13-3.47-1.92 0-3.13 1.47-3.13 3.47 0 1.98 1.21 3.46 3.13 3.46 1.9 0 3.13-1.48 3.13-3.46z"
          className="st0"
          fill="#665f5f"
        />
        <path
          id="path44"
          d="M1094.05 145.47c1.02-.06 1.6-.5 1.96-3.39l.52-4.28h5.22v7.58h.78v2.52h-1.06v-1.57h-6.35v1.57h-1.06v-2.43zm1.7-.09h4.94v-6.64h-3.21l-.42 3.41c-.22 1.81-.68 2.76-1.31 3.23z"
          className="st0"
          fill="#665f5f"
        />
        <path
          id="path46"
          d="M1103.88 146.32v-8.53h5.59v.95h-4.53v2.75h4.44v.95h-4.44v2.94h4.53v.95h-5.59z"
          className="st0"
          fill="#665f5f"
        />
        <path
          id="path48"
          d="M1111.15 146.32v-8.53h1.06v6.66l4.91-6.66h1.09v8.53h-1.06v-6.8l-4.97 6.8zm3.53-9.01c-.91 0-1.73-.44-2.25-1.13l.47-.41c.41.56 1.02.92 1.78.92.74 0 1.37-.35 1.76-.92l.47.41c-.5.7-1.31 1.13-2.23 1.13z"
          className="st0"
          fill="#665f5f"
        />
        <path
          id="path50"
          d="M1119.86 142.07c0-2.62 1.93-4.41 4.39-4.41 1.51 0 2.56.73 3.24 1.69l-.9.5a2.875 2.875 0 00-2.34-1.24c-1.87 0-3.29 1.43-3.29 3.47 0 2.02 1.42 3.46 3.29 3.46.97 0 1.85-.51 2.34-1.24l.91.5c-.72.97-1.74 1.69-3.25 1.69-2.46-.01-4.39-1.8-4.39-4.42z"
          className="st0"
          fill="#665f5f"
        />
        <path
          id="path52"
          d="M1130.96 146.32v-7.58h-2.7v-.95h6.47v.95h-2.71v7.58z"
          className="st0"
          fill="#665f5f"
        />
        <path
          id="path54"
          d="M1136.13 146.32v-8.53h3.8c1.48 0 2.39.9 2.39 2.17 0 1.07-.72 1.79-1.5 1.96.91.14 1.66 1.07 1.66 2.1 0 1.37-.91 2.3-2.47 2.3zm5.09-6.2c0-.77-.51-1.38-1.47-1.38h-2.56v2.75h2.56c.96 0 1.47-.59 1.47-1.37zm.17 3.79c0-.78-.54-1.47-1.59-1.47h-2.61v2.94h2.61c1 0 1.59-.56 1.59-1.47z"
          className="st0"
          fill="#665f5f"
        />
        <path
          id="path56"
          d="M1144.17 146.32v-8.53h1.06v6.66l4.91-6.66h1.09v8.53h-1.06v-6.8l-4.97 6.8z"
          className="st0"
          fill="#665f5f"
        />
        <path
          id="path58"
          d="M1152.83 146.32l2.3-3.48c-1.16-.1-2.22-.95-2.22-2.48 0-1.57 1.11-2.57 2.69-2.57h3.43v8.53h-1.06v-3.39h-1.71l-2.17 3.39zm5.13-4.32v-3.26h-2.25c-1 0-1.7.67-1.7 1.62 0 .95.7 1.64 1.7 1.64z"
          className="st0"
          fill="#665f5f"
        />
        <path
          id="path60"
          d="M973.3 154.85c0-2.62 1.93-4.41 4.39-4.41 1.51 0 2.56.73 3.23 1.69l-.89.5a2.875 2.875 0 00-2.34-1.24c-1.87 0-3.29 1.43-3.29 3.47 0 2.02 1.42 3.46 3.29 3.46.97 0 1.85-.51 2.34-1.24l.91.5c-.72.97-1.74 1.69-3.25 1.69-2.46-.01-4.39-1.8-4.39-4.42z"
          className="st0"
          fill="#665f5f"
        />
        <path
          id="path62"
          d="M988.29 158.32c1.02 0 1.6-.56 1.96-3.45l.52-4.28h5.22v8.53h-1.06v-7.58h-3.21l-.42 3.41c-.46 3.75-1.48 4.32-3 4.32v-.95z"
          className="st0"
          fill="#665f5f"
        />
        <path
          id="path64"
          d="M997.64 154.85c0-2.52 1.7-4.41 4.23-4.41 2.52 0 4.23 1.89 4.23 4.41 0 2.52-1.71 4.41-4.23 4.41-2.53 0-4.23-1.89-4.23-4.41zm7.36 0c0-2-1.23-3.47-3.13-3.47-1.92 0-3.13 1.47-3.13 3.47 0 1.98 1.21 3.46 3.13 3.46 1.91.01 3.13-1.48 3.13-3.46z"
          className="st0"
          fill="#665f5f"
        />
        <path
          id="path66"
          d="M1012.92 159.11l-3.24-3.84-.86.97v2.86h-1.06v-8.53h1.06v4.41l3.77-4.41h1.33l-3.56 4.05 3.86 4.48h-1.3z"
          className="st0"
          fill="#665f5f"
        />
        <path
          id="path68"
          d="M1015.1 154.85c0-2.52 1.7-4.41 4.23-4.41 2.52 0 4.23 1.89 4.23 4.41 0 2.52-1.71 4.41-4.23 4.41-2.53 0-4.23-1.89-4.23-4.41zm7.37 0c0-2-1.23-3.47-3.13-3.47-1.92 0-3.13 1.47-3.13 3.47 0 1.98 1.21 3.46 3.13 3.46 1.9.01 3.13-1.48 3.13-3.46z"
          className="st0"
          fill="#665f5f"
        />
        <path
          id="path70"
          d="M1032.51 159.11v-7.02l-2.9 7.02h-.43l-2.89-7.02v7.02h-1.06v-8.53h1.52l2.65 6.42 2.66-6.42h1.52v8.53z"
          className="st0"
          fill="#665f5f"
        />
        <path
          id="path72"
          d="M1035.22 154.85c0-2.52 1.7-4.41 4.23-4.41 2.52 0 4.23 1.89 4.23 4.41 0 2.52-1.71 4.41-4.23 4.41-2.53 0-4.23-1.89-4.23-4.41zm7.37 0c0-2-1.23-3.47-3.13-3.47-1.92 0-3.13 1.47-3.13 3.47 0 1.98 1.21 3.46 3.13 3.46 1.9.01 3.13-1.48 3.13-3.46z"
          className="st0"
          fill="#665f5f"
        />
        <path
          id="path74"
          d="M1047.46 159.11v-7.58h-2.7v-.95h6.47v.95h-2.71v7.58z"
          className="st0"
          fill="#665f5f"
        />
        <path
          id="path76"
          d="M1052.63 159.11v-8.53h1.06v6.66l4.91-6.66h1.09v8.53h-1.06v-6.8l-4.97 6.8z"
          className="st0"
          fill="#665f5f"
        />
        <path
          id="path78"
          d="M1061.68 159.11v-8.53h3.8c1.48 0 2.39.9 2.39 2.17 0 1.07-.72 1.79-1.5 1.96.91.14 1.66 1.07 1.66 2.1 0 1.37-.91 2.3-2.47 2.3zm5.09-6.2c0-.77-.51-1.38-1.47-1.38h-2.56v2.75h2.56c.96-.01 1.47-.59 1.47-1.37zm.17 3.78c0-.78-.54-1.47-1.59-1.47h-2.61v2.94h2.61c1 0 1.59-.56 1.59-1.47z"
          className="st0"
          fill="#665f5f"
        />
        <path
          id="path80"
          d="M1069.38 154.85c0-2.52 1.7-4.41 4.23-4.41 2.52 0 4.23 1.89 4.23 4.41 0 2.52-1.71 4.41-4.23 4.41-2.53 0-4.23-1.89-4.23-4.41zm7.37 0c0-2-1.23-3.47-3.13-3.47-1.92 0-3.13 1.47-3.13 3.47 0 1.98 1.21 3.46 3.13 3.46 1.9.01 3.13-1.48 3.13-3.46z"
          className="st0"
          fill="#665f5f"
        />
        <path
          id="path82"
          d="M1086.79 159.11v-7.02l-2.9 7.02h-.43l-2.89-7.02v7.02h-1.06v-8.53h1.52l2.65 6.42 2.66-6.42h1.52v8.53z"
          className="st0"
          fill="#665f5f"
        />
      </g>
      <path id="rect86" fill="#343539" d="M204.36 0h1v48h-1z" />
    </svg>
  )
}
