import React, { useState } from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image"
import { useMatch } from "@reach/router"
import { css } from "styled-components/macro"
import tw from "twin.macro"
import { SvlLogo } from "./SvlLogo"

const ToggleButton: React.FC<{
  isOpen: boolean
  onClick: () => void
}> = ({ isOpen, onClick }) => (
  <button
    type="button"
    onClick={onClick}
    css={tw`block text-gray-500 hover:text-white focus:text-white focus:outline-none mr-3 sm:hidden`}
  >
    <svg css={tw`h-6 w-6 fill-current`} viewBox="0 0 24 24">
      {isOpen ? (
        <path
          fillRule="evenodd"
          d="M18.278 16.864a1 1 0 0 1-1.414 1.414l-4.829-4.828-4.828 4.828a1 1 0 0 1-1.414-1.414l4.828-4.829-4.828-4.828a1 1 0 0 1 1.414-1.414l4.829 4.828 4.828-4.828a1 1 0 1 1 1.414 1.414l-4.828 4.829 4.828 4.828z"
        />
      ) : (
        <path
          fillRule="evenodd"
          d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z"
        />
      )}
    </svg>
  </button>
)
export const Nav = () => {
  const matchIndex = useMatch("/") || useMatch("/nposvl-test/")

  const [isOpen, setIsOpen] = useState(false)

  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          menu {
            path
            title
          }
          title
        }
      }
      trainsImage: file(relativePath: { eq: "trains.png" }) {
        childImageSharp {
          fixed(width: 221, height: 132) {
            ...GatsbyImageSharpFixed
          }
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const { menu } = data.site.siteMetadata

  return (
    <header
      css={[
        tw`flex flex-wrap justify-between bg-gray-900 font-display uppercase sm:items-end`,
        css`
          font-size: x-small;
          color: #7b7b7b;
        `,
      ]}
    >
      <div css={tw`relative h-12 sm:h-24`}>
        {matchIndex ? (
          <Link css={[tw`absolute top-1.25 left-2 sm:top-4 sm:left-4`]} to="/">
            <Img
              fluid={data.trainsImage.childImageSharp.fluid}
              css={[tw`w-16 sm:w-48`]}
            />
          </Link>
        ) : (
          <Link css={tw`absolute top-2 left-8 sm:top-8`} to="/">
            <SvlLogo css={tw`h-8`} />
          </Link>
        )}
      </div>

      <ToggleButton isOpen={isOpen} onClick={() => setIsOpen(!isOpen)} />

      <nav
        css={[
          tw`w-full mb-2 sm:mx-3 sm:w-auto sm:block sm:mb-0 lg:mx-auto`,
          isOpen ? tw`block` : tw`hidden`,
        ]}
      >
        <div css={tw`sm:flex`}>
          {menu.map((mi) => (
            <Link
              key={mi.title}
              partiallyActive
              to={mi.path}
              css={[
                tw`block hover:text-blue-700 sm:rounded-t p-3 sm:px-4 md:px-8`,
                css`
                  &.active {
                    ${tw`bg-bg text-gray-900`}
                  }
                `,
              ]}
              activeClassName="active"
            >
              {mi.title}
            </Link>
          ))}
        </div>
      </nav>
    </header>
  )
}
